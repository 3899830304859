@tailwind base;
@tailwind components;

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 0px;
}
::-webkit-scrollbar-thumb {
  background-color: transparent;
  -webkit-box-shadow: transparent;
}

::-webkit-scrollbar-track {
  background: transparent;
}

.visible-scrollbar {
  display: block;
  overflow: auto;
}

.visible-scrollbar::-webkit-scrollbar {
  width: 10px;
  scrollbar-width: thin;
  scrollbar-color: #bcbcbc #fff;
}

.visible-scrollbar::-webkit-scrollbar-track {
  background: #fff;
}

.visible-scrollbar::-webkit-scrollbar-thumb {
  background-color: #bcbcbc;
  border-radius: 6px;
  border: 3px solid #fff;
}

.MuiFormLabel-root {
  @apply text-sm font-normal text-gray-1300 !important;
}

.MuiInputBase-input {
  @apply text-sm text-black;
}

.MuiOutlinedInput-input {
  @apply py-3 px-2.5;
}

.MuiInput-underline:before {
  @apply border-b border-gray-1300 !important;
}

.MuiFormLabel-root.Mui-focused {
  @apply text-black !important;
}

.MuiInput-underline.Mui-focused:after {
  @apply border-b-2 border-gray-1300 !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  @apply border border-black !important;
}

.MuiOutlinedInput-root.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline {
  @apply border border-red !important;
}

.MuiAutocomplete-endAdornment {
  @apply top-0 !important;
}

.MuiAutocomplete-endAdornment .MuiButtonBase-root {
  @apply p-1 !important;
}

.MuiAutocomplete-endAdornment .MuiAutocomplete-clearIndicator {
  @apply text-tiny !important;
}

.MuiAutocomplete-inputRoot .MuiAutocomplete-input {
  @apply w-16 !important;
}

.swal2-styled.swal2-confirm {
  @apply bg-black px-8 text-sm ring-black !important;
}

.swal2-styled.swal2-cancel {
  @apply !border border-black bg-transparent px-8 text-sm text-black ring-black !important;
  border: 1px solid !important;
}

.swal2-styled.swal2-cancel:focus {
  @apply bg-gray-500 ring-1 ring-gray-500 !important;
}

.swal2-styled.swal2-confirm:focus {
  @apply ring-1 ring-black !important;
}

.swal2-title {
  @apply text-tiny font-medium text-gray-800 !important;
}

.swal2-icon {
  @apply h-12 w-12 !important;
}

.swal2-popup {
  @apply z-1500 w-[max-content] px-8 !important;
}

.swal2-icon.swal2-warning {
  @apply border-black text-black !important;
}

.trimblemaps-canvas {
  @apply h-full w-full;
}

#headlessui-portal-root > div > .fixed {
  @apply z-1500;
}

.MuiTab-root {
  @apply text-xs text-black !important;
}

.MuiTabs-indicator {
  @apply bg-black !important;
}

.MuiTabs-scroller.MuiTabs-fixed.css-jpln7h-MuiTabs-scroller {
  @apply h-10 !important;
}

p.MuiFormHelperText-root.MuiFormHelperText-sizeMedium.MuiFormHelperText-contained {
  @apply leading-none !important;
}

.MuiFormLabel-asterisk {
  @apply text-red !important;
}

.bounce-loader div:first-child {
  animation-delay: 0.05s !important;
}

.bounce-loader div:nth-child(2) {
  animation-delay: 0.1s !important;
}

.bounce-loader div:nth-child(3) {
  animation-delay: 0.15s !important;
}

.MuiTooltip-popper {
  z-index: 1500 !important;
}

.profile-canvas-particles {
  @apply relative h-[186px] w-full md:h-[290px] !important;
}

.MuiInputBase-root.Mui-disabled:before {
  border-bottom-style: solid !important;
}

::placeholder {
  color: #bcbcbc !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.trimblemaps-popup-close-button {
  margin-right: 5px;
}

.statistics .slick-arrow::before {
  @apply hidden;
}

.statistics .slick-track {
  padding-top: 16px;
}

iframe {
  @apply pointer-events-none;
}

@tailwind utilities;

.MuiDialogActions-root .MuiButton-root {
  @apply leading-normal;
}

.MuiDialogContent-root div[role="presentation"] {
  @apply leading-normal;
}
